import { CommunityUserCardProps } from '@components/CommunityUserCard/CommunityUserCard';
import { COMMUNITY_TYPE } from '@components/forms/experts/ExpertsForm';
import { sendGTMEvent } from '@next/third-parties/google';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { UploadFilesStore, useUploadFilesStore } from '@providers/hooks/fileuploads/UploadFilesStoreContext';
import { useSession } from '@providers/user/SessionContext';
import { Thematic, ThematicExpert, UserRegisterLocationData } from '@service/model';
import { UserSessionData } from '@service/model/session';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';


export enum TicketStatus {
  INITIAL,
  LOADING,
  ERROR,
  OK,
}

export interface TicketErrors {
  expert?: boolean
  subcategory?: boolean,
  text?: boolean,
  location?: boolean,
  message?: string,
}

interface BackErrors {
  ubicacion: string;
  usuario_responsable_id?: string;
  empresa_id: string;
  subcategoria_id: string;
  mensaje: string;
  descripcion: string;
}

interface NewTicketContextInterface {
  viewForm: boolean;
  iKnowWhoToConsult: boolean;
  activeStepIndex: number;
  preselectedExpert: boolean;
  expert: CommunityUserCardProps;
  ticketText: string;
  thematic: Thematic;
  completeThematic: Thematic | ThematicExpert;
  subcategory: Thematic;
  thematicID: number;
  expertID: number;
  stateTicket: TicketStatus;
  uploadFilesStore: UploadFilesStore;
  ticketLocation: UserRegisterLocationData;
  ticketsErrors: TicketErrors;
  canConsult: boolean;
  exitForm: boolean;
  searchText: string;
  expertsType: COMMUNITY_TYPE;
  firstTimeWithType: boolean;
  handleNext: () => void;
  handleBack: () => void;
  updatePreselectedExpert: (newPreselectedExperted: boolean) => void;
  updateStepIndex: (newIndex: number) => void;
  updateExpert: (CommunityUserCardProps) => void;
  updateThematic: (newCategory: Thematic | ThematicExpert) => void;
  updateCompleteThematic: (newCategory: Thematic | ThematicExpert) => void;
  updateSubcategory: (newSubcategory: Thematic | ThematicExpert) => void;
  updateThematicID: (newThematicID: number) => void;
  updateExpertID: (newExpertID: number) => void;
  removeCategories: () => void;
  removeExpert: () => void;
  removeTicketLocation: () => void;
  updateTicketError: (name: string, newTicketError: boolean) => void;
  updateTicketText: (text: string) => void;
  updateTicketLocation: (newLocation: UserRegisterLocationData) => void;
  updateViewForm: (newValue: boolean) => void;
  updateIKnowWhoToConsult: (newValue: boolean) => void;
  checkData: (setErrors: boolean) => boolean;
  createTicket: (user: UserSessionData) => void;
  updateExitForm: (newValue: boolean) => void;
  updateSearchText: (text: string) => void;
  preselectedThematic: boolean;
  updatePreselectedThematic: (newPreselectedThematic: boolean) => void;
  updateExpertsType: (newExpertsType: COMMUNITY_TYPE) => void;
  updateCanConsult: (newValue: boolean) => void;
  updateFirstTimeWithType: (newValue: boolean) => void;
}


const useNewTicket: () => NewTicketContextInterface = () => {

  const [iKnowWhoToConsult, setIKnowWhoToConsult] = useState<boolean>(false);
  const [viewForm, setViewForm] = useState<boolean>(false);
  const [canConsult, setCanConsult] = useState<boolean>(true);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const uploadFilesStore = useUploadFilesStore();
  const [expert, setExpert] = useState<CommunityUserCardProps>(null);
  const [thematic, setThematic] = useState<Thematic>(null);
  const [completeThematic, setCompleteThematic] = useState<Thematic>(null);
  const [subcategory, setSubcategory] = useState<Thematic>(null);
  const [ticketLocation, setTicketLocation] = useState<UserRegisterLocationData>();
  const [ticketText, setTicketText] = useState<string>('');
  const [stateTicket, setStateTicket] = useState<TicketStatus>(TicketStatus.INITIAL);
  const [preselectedExpert, setPreselectedExpert] = useState<boolean>(true);
  const [thematicID, setThematicID] = React.useState(null);
  const [expertID, setExpertID] = React.useState(null);
  const [exitForm, setExitForm] = useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const [expertsType, setExpertsType] = React.useState<COMMUNITY_TYPE>(COMMUNITY_TYPE.EXPERTS);
  const [firstTimeWithType, setFirstTimeWithType] = React.useState<boolean>(true);
  const { snackbars } = useCustomSnackbar();

  const [ticketsErrors, setTicketsErrors] = React.useState<TicketErrors>({
    expert: false,
    subcategory: false,
    location: false,
    text: false,
    message: undefined,
  });

  const [preselectedThematic, setPreselectedThematic] = useState<boolean>(false);

  const { refreshToken } = useSession();

  const handleNext = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
  };

  const updateStepIndex = (newIndex: number) => {
    setActiveStepIndex(newIndex);
  };

  const updateIKnowWhoToConsult = (newValue: boolean) => {
    setIKnowWhoToConsult(newValue);
  };

  const updateCanConsult = (newValue: boolean) => {
    setCanConsult(newValue);
  };

  const updateViewForm = (newValue: boolean) => {
    setViewForm(newValue);
  };

  const updateExitForm = (newValue: boolean) => {
    setExitForm(newValue);
  };

  const updatePreselectedExpert = (newPreselectedExpert: boolean) => {
    setPreselectedExpert(newPreselectedExpert);
  };

  const updatePreselectedThematic = (newPreselectedThematic: boolean) => {
    setPreselectedThematic(newPreselectedThematic);
  };

  const updateExpert = (newExpert: CommunityUserCardProps) => {
    setExpert(newExpert);
  };

  const updateTicketText = (text: string) => {
    setTicketText(text);
  };

  const updateThematic = (newThematic: Thematic) => {
    setThematic(newThematic);
  };

  const updateCompleteThematic = (newThematic: Thematic) => {
    setCompleteThematic(newThematic);
  };

  const updateSubcategory = (newSubcategory: Thematic) => {
    setSubcategory(newSubcategory);
  };

  const updateTicketLocation = (newTicketLocation: UserRegisterLocationData) => {
    setTicketLocation(newTicketLocation);
  };

  const removeCategories = () => {
    setThematic(null);
    setCompleteThematic(null);
    setSubcategory(null);
    setThematicID(null);
    setSearchText('');
  };

  const removeExpert = () => {
    setExpert(null);
    setSearchText('');
  };

  const removeTicketLocation = () => {
    setTicketLocation(undefined);
  };

  const updateTicketError = (name: keyof TicketErrors, newTicketError: boolean) => {
    setTicketsErrors({ ...ticketsErrors, [name]: newTicketError });
  };

  const updateThematicID = (newvalue: number) => {
    setThematicID(newvalue);
  };

  const updateExpertID = (newvalue: number) => {
    setExpertID(newvalue);
  };

  const updateSearchText = (text: string) => {
    setSearchText(text);
  };

  const updateExpertsType = (newExpertsType: COMMUNITY_TYPE) => {
    setExpertsType(newExpertsType);
  };

  const updateFirstTimeWithType = (newValue: boolean) => {
    setFirstTimeWithType(newValue);
  };

  const backendErrors = (errors: BackErrors) => {

    setTicketsErrors(
      {
        location: !!errors.ubicacion,
        expert: !!errors.usuario_responsable_id || !!errors.empresa_id,
        subcategory: !!errors.subcategoria_id,
        text: !!errors.mensaje,
        message: errors.descripcion,
      })
    ;
  };

  const checkData = (sendTicket: boolean) => {

    if (expert === undefined || subcategory === null || ticketText.length < 50 || ticketText.length > 800 || ticketLocation === undefined) {

      if (sendTicket) {
        setTicketsErrors({
          expert: expert === null,
          subcategory: subcategory === null,
          text: ticketText === '',
          location: ticketLocation === null,
        });

        setStateTicket(TicketStatus.ERROR);
      }

      return false;
    } else {

      if (sendTicket) {
        setStateTicket(TicketStatus.LOADING);
      }

      return true;
    }

  };

  const createTicket = (user: UserSessionData) => {
    if (checkData(true)) {
      refreshToken().then(() => {
        axios.post<ResponseResult>('/api/tickets/new', {
          text: ticketText,
          expert: expert,
          user: user,
          subcategory: subcategory.subcategoryId,
          location: ticketLocation,
          attachments: uploadFilesStore.getAll(),
        })
          .then((res) => {

            if (res.data.errors) {
              setStateTicket(TicketStatus.ERROR);
              updateStepIndex(activeStepIndex - 1);
              // @ts-ignore
              backendErrors(res.data.errors);
            } else {
              setStateTicket(TicketStatus.OK);
              snackbars.showSuccess('Consulta creada con éxito');

              sendGTMEvent({event:'ticket_created', category: 'ticket_creation', label: 'Creo una consulta',
                gender: user?.gender == 1 ? 'male' : user?.gender == 2 ? 'female' : 'undefined'});
            }

          })
          .catch(() => setStateTicket(TicketStatus.ERROR));
      }).catch((e) => {
        console.error(e);
      });
    } else {
      updateStepIndex(activeStepIndex - 1);
    }
  };

  return {
    iKnowWhoToConsult,
    canConsult,
    viewForm,
    preselectedExpert,
    preselectedThematic,
    activeStepIndex,
    expert,
    thematic,
    completeThematic,
    subcategory,
    thematicID,
    expertID,
    ticketText,
    ticketLocation,
    uploadFilesStore,
    stateTicket,
    ticketsErrors,
    exitForm,
    searchText,
    expertsType,
    firstTimeWithType,
    handleNext,
    handleBack,
    updateStepIndex,
    updatePreselectedExpert,
    updatePreselectedThematic,
    updateIKnowWhoToConsult,
    updateCanConsult,
    updateViewForm,
    updateExpert,
    updateTicketText,
    updateThematic,
    updateCompleteThematic,
    updateThematicID,
    updateExpertID,
    updateSubcategory,
    updateTicketLocation,
    removeCategories,
    removeExpert,
    removeTicketLocation,
    updateTicketError,
    updateExitForm,
    updateSearchText,
    updateExpertsType,
    checkData,
    createTicket,
    updateFirstTimeWithType,
  };
};


const NewTicketProvider: React.FC = ({ children }) => {
  const newTicket = useNewTicket();

  return (
    <NewTicketContext.Provider value={newTicket}>
      {children}
    </NewTicketContext.Provider>
  );
};

export default NewTicketProvider;
export const NewTicketContext = createContext<NewTicketContextInterface>(null);

export const useNewTicketContext = () => useContext(NewTicketContext);


